<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frmTier')" data-vv-scope="frmTier" novalidate="novalidate">
    <div class="row justify-content-md-center">

      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('sidebar.tier') }}</h5>
            <div class="row">
              <!-- Tipo Carga -->
              <div class="col-sm-4 form-group mb-4">
                <label>{{ this.$i18n.t('form.tierLocal.tipoCarga') }}</label>
                <v-select name="tier.tipoCarga" label="tier.tipoCarga" :clearable="false" :searchable="true" v-model="tier.tipoCarga"  :options="tipoCargaList">
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
              </div>
              <!-- Tipo -->
              <div class="col-sm-4 form-group mb-4">
                <label>{{ this.$i18n.t('form.tier.type') }}</label>
                <v-select name="tier.type" label="nome" :clearable="false"
                  :searchable="true" v-model="tier.type" :options="typeList"
                  @input="onSelectType"
                >
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row">
              <!-- Código -->
              <custom-input
                v-model="tier.code"
                name="tier.code"
                :label="this.$i18n.t('form.tier.code')"
                type="text"
                rootClassName="col-sm-4 form-group mb-4"
                :max-length="25"
                v-validate="{ required: true }"
                :error="submitted = errors.first('tier.code')">
              </custom-input>
              <!-- Moeda -->
              <div class="col-sm-4 form-group mb-4">
                <label>{{ this.$i18n.t('form.tier.currency') }}</label>
                <v-select   class="form-group"
                            :disabled="showLclRange"
                            label="sigla"
                            v-model="tier.currency"
                            name="tier.currency"
                            :searchable="true"
                            :clearable="false"
                            :options="currencyList"
                            @input="onSelectCurrency"
                            :filter-by="currencyFilter"
                            v-validate="{ required: true }"
                >
                  <template slot="no-options">
                    {{ $t('empty') }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
                <transition name="slide" mode="">
                  <small v-if="(submitted) ? errors.first('tier.currency'): ''" class="royalc-error-field">{{ errors.first('tier.currency') }}</small>
                </transition>
              </div>

              <!-- Valor -->
              <custom-input
                v-if="!showLclRange"
                v-model="tier.value"
                name="tier.value"
                :label="this.$i18n.t('form.tier.value')"
                type="text"
                rootClassName="col-sm-4 form-group mb-4"
                :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
                :error="submitted && errors.first('tier.value')">
              </custom-input>

              <!-- Origem Grupo -->
              <origin-destination-group-field
                name="tier.idOrigemDestinoGrupo"
                v-if="showLclRange"
                v-model="tier.idOrigemDestinoGrupo"
                :grey-field="false"
                label="Origem/Destino Grupo"
              />
            </div>
            <div class="row" v-if="showLclRange">
              <h5 class="box-title mb-3">{{ $t('form.tier.ranges') }}</h5>

              <!-- Range -->
              <div class="col-12 col-sm-12 form-group mb-4">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>
                      CBM Mínimo
                    </th>
                    <th>
                      CBM Máximo
                    </th>
                    <th>
                      Valor Frete/WM
                    </th>
                  </tr>
                  </thead>
                  <tbody v-for="(range, index) in tier.ranges" v-bind:key="index">
                    <tr>
                      <td>
                        {{ $util.formatNumber(range.cbmMinimo) }}
                      </td>
                      <td>
                        {{ $util.formatNumber(range.cbmMaximo) }}
                      </td>
                      <td>
                        <custom-input
                          v-model="tier.ranges[index].valorRecebimentoUnitario"
                          :name="'[\'ranges\'][' + index + '][\'valorRecebimentoUnitario\']'"
                          type="text"
                          rootClassName="col-sm-4 form-group m-0"
                          :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
                          >
                        </custom-input>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>

          </div>
        </div>
      </div>

      <!-- Submit -->
      <div class="col-12">
        <button type="button" class="btn btn btn-outline-light pull-right" @click="onSaveTier">
          Salvar
        </button>
        <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancelTier">
          Cancelar
        </button>
      </div>

    </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'
import OriginDestinationGroupField from '@/components/Forms/OriginDestinationGroupField'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import CurrencyService from '@/services/CurrencyService'
import TierService from '@/services/TierService'

export default {
  name: 'TierForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('basic.tier.title') + ' - %s'
    }
  },
  data () {
    return {
      formErrors: {},
      typeList: [
        {
          id: 1,
          nome: 'Fixo'
        },
        // {
        //   id: 2,
        //   nome: 'Percentual'
        // },
        {
          id: 3,
          nome: 'LCL - Faixa'
        }
      ],
      tipoCargaList: [
        {
          id: 3,
          nome: 'FCL'
        },
        {
          id: 4,
          nome: 'LCL'
        }
      ],
      isLoading: true,
      fullPage: true,
      processEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/tier',
      // List of options to select on select input
      tierList: [],
      currencyList: [],
      origemDestinoGrupoList: [],
      //  Selected Values (Form)
      tier: {
        tipoCarga: null,
        code: null,
        type: {
          id: 1,
          nome: 'Fixo'
        },
        idOrigemDestinoGrupo: null,
        ranges: [
          {
            cbmMinimo: 0,
            cbmMaximo: 1.0,
            valorRecebimentoUnitario: null
          },
          {
            cbmMinimo: 1.01,
            cbmMaximo: 3.0,
            valorRecebimentoUnitario: null
          },
          {
            cbmMinimo: 3.01,
            cbmMaximo: 6.0,
            valorRecebimentoUnitario: null
          },
          {
            cbmMinimo: 6.01,
            cbmMaximo: 10.0,
            valorRecebimentoUnitario: null
          },
          {
            cbmMinimo: 10.01,
            cbmMaximo: 15.0,
            valorRecebimentoUnitario: null
          }
        ],
        value: null,
        currency: null
      },
      submitted: false
    }
  },
  components: {
    Loading,
    vSelect,
    CustomInput,
    OriginDestinationGroupField
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    // Load basilares data
    CurrencyService.getCurrencies().then(res => {
      _this.hasData = res.data.hasData
      _this.currencyList = res.data.data

      _this.tier.currency = _this.currencyList[0]

      // If is edit populate values
      let tierId = this.$route.params.id_tier

      if (tierId) {
        TierService.getTier(tierId).then(tier => {
          _this.tier = tier.data
        }).catch(() => {
          _this.isLoading = false
        })
      }
      // End edit
      _this.isLoading = false
      // CurrencyService.getCurrencies().then(res => {
      //   _this.currencyList = res.data.data
      //
      // })
    })
  },
  computed: {
    showLclRange () {
      if (this.tier.type && this.tier.type.id === 3) {
        return true
      }

      return false
    }
  },
  methods: {
    onCancelTier (e) {
      var _this = this
      _this.$router.push({ name: 'TierIndex' })
    },
    currencyFilter (option, label, search) {
      let temp = search.toLowerCase()

      return (option.nome.toLowerCase().indexOf(temp) > -1 || option.sigla.toLowerCase().indexOf(temp) > -1)
    },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    showNumber (number, thousandsSep, defaultValue) {
      return util.showNumber(number, thousandsSep, defaultValue)
    },
    onSelectCurrency (val) {
      this.tier.currency = val
    },
    onSelectType (val) {
      if (val.id === 3) {
        this.tier.currency = {
          id: null,
          nome: 'Moeda do Frete',
          sigla: null
        }

        this.tier.value = null
      } else {
        this.tier.currency = null
        this.tier.ranges = [
          {
            cbmMinimo: 0,
            cbmMaximo: 1.0,
            valorRecebimentoUnitario: null
          },
          {
            cbmMinimo: 1.01,
            cbmMaximo: 3.0,
            valorRecebimentoUnitario: null
          },
          {
            cbmMinimo: 3.01,
            cbmMaximo: 6.0,
            valorRecebimentoUnitario: null
          },
          {
            cbmMinimo: 6.01,
            cbmMaximo: 10.0,
            valorRecebimentoUnitario: null
          },
          {
            cbmMinimo: 10.01,
            cbmMaximo: 15.0,
            valorRecebimentoUnitario: null
          }
        ]
        this.idOrigemDestinoGrupo = null
      }
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_tier: object.id } }).href
    },

    /**
     * Triggered when the currency search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    currencyFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      CurrencyService.getCurrencies(filters).then(res => {
        this.hasData = res.data.hasData
        this.currencyList = res.data.data
        loading(false)
      })
    },
    onSaveTier (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validate().then(valid => {
        if (valid) {
          _this.isLoading = true

          let tierData = {
            code: _this.tier.code,
            value: _this.tier.value,
            currency: _this.tier.currency.id,
            type: _this.tier.type.id,
            tipoCarga: _this.tier.tipoCarga.id,
            idOrigemDestinoGrupo: (_this.tier.idOrigemDestinoGrupo) ? _this.tier.idOrigemDestinoGrupo.idorigem_destino_grupo : null,
            ranges: []
          }

          for (let rangeIndex in _this.tier.ranges) {
            let range = _this.tier.ranges[rangeIndex]

            tierData.ranges.push({
              cbmMinimo: range.cbmMinimo,
              cbmMaximo: range.cbmMaximo,
              valorRecebimentoUnitario: _this.$util.getNumberFromFormated(range.valorRecebimentoUnitario)
            })
          }

          let tierId = this.$route.params.id_tier

          if (tierId) {
            TierService.editTier(tierId, tierData).then(response => {
              _this.$router.push({ name: 'TierIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            TierService.newTier(tierData).then(response => {
              _this.$router.push({ name: 'TierIndex' })
            }).catch(() => {
              _this.isLoading = false
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;
  }
</style>
